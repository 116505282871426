import connection from "../connection";

const apiUrl = localStorage.getItem("apiUrl");

export default {
  getLogoFile(id) {
    return connection.get(apiUrl + "/invoice_generator_logo_file/" + id, {
      responseType: "blob",
    });
  },
};
