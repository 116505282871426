import connection from "../connection";

export default {
  invoiceTransactionsSettlements(invoiceId, type) {
    connection
      .get(
        localStorage.getItem("apiUrl") +
          "/invoice/" +
          invoiceId +
          "/export-transaction-settlement-list/" +
          type,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "invoice_" + invoiceId + "_transactions." + type
        );
        document.body.appendChild(link);
        link.click();
      });
  },
  invoicePDF(invoiceId, type) {
    connection
      .get("/../invoice/" + invoiceId + "/download/" + type + "-pdf", {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice_" + invoiceId + ".pdf");
        document.body.appendChild(link);
        link.click();
      });
  },
};
