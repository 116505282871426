import connection from "../connection";

const apiUrl = localStorage.getItem("apiUrl");

export default {
  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  getBankAccounts() {
    return connection.get(apiUrl + "/bank/get-accounts");
  },
};
