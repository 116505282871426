import axios from "axios";
import store from "../store";
import router from "../router";
import { message } from "ant-design-vue";
import LRU from "lru-cache";
import { MD5 } from "../utils/md5";

const cache = new LRU({ maxAge: 1000 * 60 });
cache.reset();
const apiUrl = localStorage.getItem("apiUrl");

let connection = axios.create({
  baseURL: apiUrl + process.env.VUE_APP_GRAPHQL,
  timeout: 30000,
});

connection.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

connection.interceptors.response.use(
  async (response) => {
    if (response.data.errors) {
      response.data.errors.forEach(function (error) {
        message.error(error.message);
      });
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  async (error) => {
    // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
    if (
      401 === error.response.status &&
      store.getters["userStoreModule/isLogged"]
    ) {
      console.info("Token expired");
      await store.dispatch("userStoreModule/logout");
      await router.push("/login");
    }
  }
);

/**
 * @param data
 * @param userCache
 * @returns Array|Boolean
 */
connection.graph = async (data, userCache = false) => {
  let hash = undefined;
  if (data.query.indexOf("mutation") < 0) {
    hash = MD5(data.query + JSON.stringify(data.variables));
  }
  if (userCache && cache.has(hash)) {
    return await cache.get(hash);
  } else {
    return await connection.post("", data).then(function (response) {
      cache.set(hash, response.data.data);
      return response.data.data;
    });
  }
};

export default connection;
