import connection from "../connection";
import { print } from "graphql";
import payments from "../graphql/queries/payments.graphql";
import paymentFilter from "../graphql/queries/paymentFilter.graphql";
import payment from "../graphql/queries/payment.graphql";

const apiUrl = localStorage.getItem("apiUrl");

export default {
  /**
   * @returns {Array|Boolean}
   */
  getCollection(variables) {
    if (!variables.page || variables.page == 0) {
      delete variables.page;
      delete variables.pageSize;
    }
    const params = {
      ...variables,
      ...(variables.page
        ? {
            page: btoa((variables.page - 1) * variables.pageSize - 1),
            first: variables.pageSize,
          }
        : {}),
      ...(variables.amountFrom
        ? {
            amountFrom: variables.amountFrom * 100,
          }
        : delete variables.amountFrom),
      ...(variables.amountTo
        ? {
            amountTo: variables.amountTo * 100,
          }
        : delete variables.amountTo),
      ...(variables.datePayoutFrom
        ? {
            datePayoutFrom: variables.datePayoutFrom
              .startOf("day")
              .unix()
              .toString(),
          }
        : { datePayoutFrom: "0" }),
      ...(variables.datePayoutTo
        ? {
            datePayoutTo: variables.datePayoutTo.endOf("day").unix().toString(),
          }
        : delete variables.datePayoutTo),
    };

    if (params.amountFrom != undefined) {
      params.amountFrom = params.amountFrom.toString();
    }
    if (params.amountTo != undefined) {
      params.amountTo = params.amountTo.toString();
    }
    if (params.fromId != undefined) {
      params.fromId = parseInt(params.fromId);
    }

    /** Convert arrays to int */
    const convert = [
      "companyTo_id_list",
      "companyFrom_id_list",
      "paymentOptionFromId_list",
      "paymentOptionToId_list",
      "invoices_id_list",
      "status_list",
    ];
    for (const key of convert) {
      if (params[key] !== undefined) {
        params[key] = params[key].toString().split(",").map(Number);
      }
    }

    return connection.graph({
      query: print(payments),
      variables: params,
    });
  },
  /**
   * @returns {Array|Boolean}
   */
  getFilters() {
    return connection.graph({
      query: print(paymentFilter),
    });
  },
  /**
   * @returns {Array|Boolean}
   */
  getItem(id) {
    return connection.graph({
      query: print(payment),
      variables: { id: "/api/fapi/payments/" + id },
    });
  },

  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  getPaymentStatusList() {
    return connection.get(apiUrl + "/invoice-payments/get-statuses-list");
  },

  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  createPayment(data) {
    return connection.post(apiUrl + "/invoice-payments/create", data);
  },

  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  changeStatus(paymentId, status) {
    return connection.post(apiUrl + "/invoice-payments/change-status", {
      paymentId: paymentId,
      status: status,
    });
  },
};
