import connection from "../connection";
import { print } from "graphql";
import companies from "../graphql/queries/companies.graphql";
import companiesForSelect from "../graphql/queries/companiesForSelect.graphql";

const apiUrl = localStorage.getItem("apiUrl");

export default {
  /**
   * @param type
   * @param parent_id
   * @param id
   * @returns {Array|Boolean}
   */
  getCollection(type, id, parent_id) {
    return connection.graph({
      query: print(companies),
      variables: { type, id, parent_id },
    });
  },
  /**
   * @param company_id
   * @param company_type_list
   * @returns {Array|Boolean}
   */
  getForSelect(company_id, company_type_list = []) {
    return connection.graph({
      query: print(companiesForSelect),
      variables: {
        company_id,
        company_type_list,
      },
    });
  },

  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  getWhitelabelOwner() {
    return connection.get(apiUrl + "/company/get-whitelabel-owner");
  },
};
