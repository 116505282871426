import isEmpty from "lodash/isEmpty";

/**
 * @param fields
 * @param result
 * @returns {{}}
 */
import { isNumeric } from "./numeric";

export function flattenGraph(fields = {}, result = {}) {
  let formItem = {};

  if (fields.select !== undefined) {
    fields.select.forEach(function (nestedField) {
      if (result[nestedField] && result[nestedField]._id !== undefined) {
        formItem[nestedField] = parseInt(result[nestedField]._id);
      } else if (
        result[nestedField] &&
        result[nestedField].code !== undefined
      ) {
        formItem[nestedField] = result[nestedField].code;
      }
    });
  }

  if (fields.multi !== undefined) {
    fields.multi.forEach(function (nestedField) {
      if (
        result[nestedField] !== undefined &&
        !isEmpty(result[nestedField].edges)
      ) {
        formItem[nestedField] = [];
        result[nestedField].edges.forEach(function (edge) {
          if (edge.node.id.includes("terminal")) {
            formItem[nestedField].push(edge.node._id);
          } else {
            formItem[nestedField].push(parseInt(edge.node._id));
          }
        });
      }
    });
  }

  if (fields.flat !== undefined) {
    fields.flat.forEach(function (field) {
      if (
        field === "_id" &&
        (String(result[field]).length > 16 || !isNumeric(result[field]))
      ) {
        formItem[field] = result[field];
      } else {
        formItem[field] = isNumeric(result[field])
          ? parseFloat(result[field])
          : result[field];
      }
    });
    return formItem;
  }
}
